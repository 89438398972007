<template>
  <b-form-group label="Группировка*" :inline="true">
    <div class="d-flex select-wrapper">
      <b-input-group-text>
        <i class="icon-people"></i>
      </b-input-group-text>
      <multiselect
        v-model="selectedFraction"
        :options="fractions"
        placeholder="Выберите группировку"
        :multiple="false"
        :allow-empty="false"
        :showLabels="false"
        label="title"
        return="id"
        track-by="id"
      >
        <template slot="singleLabel" slot-scope="props">
          <img
            v-if="props.option.img"
            class="option__image"
            :src="props.option.img"
            width="25"
            height="25"
            alt
          />
          <span class="option__desc">
            <span class="option__title ml-1">{{ props.option.title }}</span>
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <img
            class="option__image"
            :src="props.option.img"
            width="20"
            height="20"
            alt="."
          />
          <u>
            <span class="option__title ml-1">
              <b>({{ props.option.places_left }})</b>
              {{ props.option.title }}
            </span>
          </u>
          <div class="option__desc mt-2">
            <span class="option__small">
              <small>{{ props.option.desc }}</small>
            </span>
          </div>
        </template>
      </multiselect>
    </div>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Fractions",
  components: { Multiselect },
  props: ["fractions"],
  data() {
    return {
      selectedFraction: {}
    };
  },
  watch: {
    selectedFraction(newValue) {
      this.$emit("onValueChange", "fraction_id", newValue.id);
    }
  }
};
</script>

<style scoped>
.option__image {
  position: relative;
  bottom: 2px;
  left: 2px;
}

.option__desc {
  margin-left: 10px;
  word-wrap: break-word;
}

.option__title,
.option__desc {
  font: inherit;
}
</style>

<template>
  <div class="animated fadeIn">
    <b-button
      size="md"
      variant="primary"
      class="mb-3"
      @click="() => { $router.go(-1) }"
    >
      <i class="icon-arrow-left small"></i> Назад
    </b-button>
    <h6 class="mb-2">Обратите внимание: максимальное количество ваших персонажей - 3 .</h6>
    <h6 class="mb-2">Новый персонаж станет активным для подачи на игру. Выбирайте мудро</h6>
    <b-card no-body class="profile-container text-left">
      <b-card-body class="p-3 px-md-5 py-md-4">
        <div v-if="isPageLoading" class="text-center">
          <i class="fa fa-cog fa-spin fa-4x"></i>
        </div>
        <b-form v-if="!isPageLoading">
          <b-row>
            <b-col sm="12" md="6">
              <b-form-group label="Фото на паспорт*" :inline="true">
                <b-input-group-prepend>
                  <img
                    v-if="avatar"
                    v-bind:src="avatar"
                    width="100"
                    height="133"
                    @click="toggleCropShow"
                  />
                  <b-button
                    v-if="!avatar"
                    class="ml-2"
                    variant="primary"
                    style="z-index: 0"
                    @click="toggleCropShow"
                  >Загрузить
                  </b-button>
                  <crop-upload
                    field="img"
                    @crop-success="cropSuccess"
                    @crop-upload-success="cropUploadSuccess"
                    @crop-upload-fail="cropUploadFail"
                    v-model="cropShow"
                    :width="300"
                    :height="400"
                    :noCircle="true"
                    langType="ru"
                    :url="baseURL + 'images/uploadAvatar'"
                    :params="cropParams"
                    img-format="png"
                  ></crop-upload>
                </b-input-group-prepend>
                <small class="form-text text-muted">
                  Необходимо выбрать и аккуратно обрезать фото, так как оно будет
                  размещено на Вашем игровом паспорте.
                </small>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group label="Игровое имя*" :inline="true">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="icon-user"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <pattern-input
                    class="form-control"
                    :class="{
              'is-invalid': $v.newCharacter.callsign.$error && isTouched.callsign
            }"
                    placeholder="Позывной"
                    :regExp="validation.cyrilic.regExp"
                    :replacement="validation.cyrilic.replacement"
                    maxlength="35"
                    v-model.trim="$v.newCharacter.callsign.$model"
                  ></pattern-input>
                </b-input-group>
                <small class="form-text text-muted">Только русские буквы!</small>
                <span v-if="!$v.newCharacter.callsign.$error || !isTouched.callsign">&nbsp;</span>
                <div
                  class="invalid-error"
                  v-if="$v.newCharacter.callsign.$error && isTouched.callsign"
                >
                  <span v-if="!$v.newCharacter.callsign.required">Это обязательное для заполнения поле.</span>
                  <span v-if="!$v.newCharacter.callsign.minLength">
            Позывной должен состоять минимум из
            {{ $v.newCharacter.callsign.$params.minLength.min }} букв.
          </span>
                  <span v-if="!$v.newCharacter.callsign.isUnique">Такой позывной уже занят.</span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <Fractions
            v-on:onValueChange="onValueChange"
            v-bind:fractions="fractions"
          />

          <b-form-group label="Костюм*" description="" :inline="true">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="icon-mustache"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <multiselect
                v-model="selectedSuit"
                :options="suits"
                placeholder="Выберите костюм"
                class="form-control"
                :multiple="false"
                :showNoResults="false"
                :allow-empty="false"
                :showLabels="false"
                :disabled="isConfirmedRequest()"
                label="title"
                return="id"
                track-by="id"
              >
              </multiselect>
            </b-input-group>
            <small v-if="isConfirmedRequest()" class="form-text text-muted">
              Изменение костюма недоступно, т.к. Вы уже имеете подтвержденную
              заявку на ближайшее мероприятие
            </small>
          </b-form-group>

          <b-form-group label="Квента персонажа*" :inline="true">
            <textarea
              v-model.trim="$v.newCharacter.legend.$model"
              :disabled="isConfirmedRequest()"
              class="form-control"
              id="legend"
              name="legend"
              style="min-height: 56px"
              rows="5"
              placeholder="Опишите историю вашего персонажа"
            >
            </textarea>
            <small v-if="!isConfirmedRequest()" class="form-text text-muted">
              Не оставляйте своего персонажа без квенты
            </small>
            <div class="invalid-error" v-if="$v.newCharacter.legend.$error">
              <span v-if="!$v.newCharacter.legend.required"
              >Это обязательное для заполнения поле.</span
              >
              <span v-if="!$v.newCharacter.legend.minLength"
              >Квента должена состоять минимум из
                {{ $v.newCharacter.legend.$params.minLength.min }}
                символов.</span
              >
              <span v-if="!$v.newCharacter.legend.maxLength"
              >Квента должена состоять максимум из
                {{ $v.newCharacter.legend.$params.maxLength.max }} символов.
                Имейте совесть, уменьшите немного размер.</span
              >
            </div>
            <small v-if="isConfirmedRequest()" class="form-text text-muted">
              Изменение квенты недоступно, т.к. Вы уже имеете подтвержденную
              заявку на ближайшее мероприятие
            </small>
          </b-form-group>

          <b-form-group
            label="Несколько фото в экипировке*"
            :inline="true"
            v-if="!isConfirmedRequest()"
          >
            <b-input-group-prepend>
              <div
                class="images-upload"
                style="display: flex; justify-content: center;"
              >
                <vue-upload-multiple-image
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  @size-overflowed="tooBigImageError"
                  :data-images="photos"
                  dragText="Перетащите фото"
                  browseText="или нажмите сюда"
                  popupText="загрузите изображение"
                  dropText="Отпустите здесь"
                  accept="image/jpeg,image/png,image/bmp,image/jpg"
                  :show-primary="false"
                  :multiple="true"
                  :max-image="4"
                  :max-size="1000000"
                ></vue-upload-multiple-image>
              </div>
            </b-input-group-prepend>

            <small class="form-text text-muted" v-if="!isConfirmedRequest()">
              Прикрепляйте СВОИ фото. Каждое фото должно быть размером
              <b>до 1МБ</b>.
            </small>
          </b-form-group>

          <small v-else class="form-text text-muted mb-3">
            Изменение фотографий на допуск недоступно, т.к. Вы уже имеете подтвержденную
            заявку на ближайшее мероприятие
          </small>

          <b-button
            variant="success"
            @click="saveProfile()"
            :disabled="
              $v.$invalid ||
                newCharacter.photos_ids.length === 0 ||
                isConfirmedRequest() "
          >
            Сохранить изменения
          </b-button>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  // Components
  import Multiselect from "vue-multiselect";
  import vSelect from "vue-select";
  import PatternInput from "vue-pattern-input";
  import VueCoreImageUpload from "vue-core-image-upload";
  import CropUpload from "vue-image-crop-upload";
  import VueGallery from "vue-gallery";
  import VueUploadMultipleImage from "vue-upload-multiple-image";
  import Fractions from '../register/Fractions';
  // Validations
  import {
    integer,
    maxLength,
    minLength,
    required
  } from "vuelidate/lib/validators";
  // Utils
  import API from "../../api/stalk.net";
  import {cyrilicRegExp} from '../../shared/utils';
  import debounce from 'debounce-async';

  export default {
    name: "ProfileCharacterCreate",
    components: {
      Fractions,
      Multiselect,
      vSelect,
      PatternInput,
      VueCoreImageUpload,
      "crop-upload": CropUpload,
      gallery: VueGallery,
      VueUploadMultipleImage
    },
    data() {
      return {
        isPageLoading: false,
        baseURL: API.baseURL,
        newCharacter: {
          callsign: "",
          legend: "",
          fraction_id: null,
          suit_id: null,
          passport_photo_id: 0,
          photos_ids: []
        },
        isTouched: {
          //need for fix vuelidate and vue-pattern-input incompatibility bug
          callsign: false
        },
        validation: {
          cyrilic: {
            regExp: cyrilicRegExp,
            replacement: ""
          }
        },
        cropShow: false,
        cropParams: {
          token: "",
          name: "avatar"
        },
        avatar: null,
        photos: [],
        photosIndex: null,
        suits: [],
        selectedSuit: {},
        event: {},
        memberEvent: null
      };
    },
    validations: {
      newCharacter: {
        callsign: {
          required,
          minLength: minLength(2),
          async isUnique(value) {
            if (value === "" || value.length < 2) return true;
            let resp = debounce(API.public.isCallsignUnique, 500);
            let v = await resp(value);
            return Boolean(v.data);
          }
        },
        legend: {
          required,
          minLength: minLength(25),
          maxLength: maxLength(1800)
        },
        fraction_id: {
          required,
          integer
        },
        suit_id: {
          required,
          integer
        },
        passport_photo_id: {
          required,
          integer
        }
      }
    },
    methods: {
      onValueChange(property, value) {
        this.newCharacter[property] = value;
      },
      loadData() {
        this.isPageLoading = true;
        API.public
          .findActiveRegEvent()
          .then(resp => {
            this.event = resp.data;
            if (!this.event.IsRegistrationOpened) {
              return Promise.resolve({data: []});
            }
            return API.public.loadFractions();
          })
          .catch(e => {
            if (e && e.response && e.response.data) {
              if (e.response.data.error === "ERR_NOT_FOUND") {
                return Promise.reject(null); //do not fill error if no opened event
              }
              return Promise.reject(e);
            }
          })
          .then(resp => {
            this.fractions = resp.data;
            if (!this.event.IsRegistrationOpened) {
              return Promise.resolve({data: []});
            }
            return API.public.loadSuits();
          })
          .then(resp => {
            this.suits = resp.data;
          })
          .then(() => {
            this.isPageLoading = false;
            this.$v.$reset();
          })
          .catch(e => {
            if (e) {
              console.error(e);
              this.showRegError({message: e});
            }
            this.isPageLoading = false;
          });
      },
      tooBigImageError(size) {
        console.error(size);
        this.showError({message: "Изображение превышает 1МБ размером"});
      },
      uploadImageSuccess(formData) {
        API.public
          .uploadImages(formData)
          .then(response => {
            // this.newCharacter.photos_ids = [];
            // this.photos = [];
            let res = response.data;
            if (res) {
              if (res.error && res.error !== "") {
                let e = res.error + " - " + res.description;
                console.error(e);
                self.showError({message: e});
                return;
              }
              if (res.length === 0) {
                let e = "There is no images in response: " + res;
                console.error(e);
                self.showError({message: e});
                return;
              }
              res.forEach(img => {
                if (img.img_id && img.img_id > 0) {
                  this.newCharacter.photos_ids.push(img.img_id);
                }
              });
            }
          })
          .catch(e => {
            console.error(e);
            this.showError({message: e});
            this.isPageLoading = false;
          });
      },
      beforeRemove(index, done) {
        let self = this;
        self.newCharacter.photos_ids = self.newCharacter.photos_ids.filter(
          function (value, idx) {
            return index !== idx;
          }
        );

        done();
      },
      toggleCropShow() {
        if (!this.isHasPassport()) {
          this.cropShow = !this.cropShow;
        }
      },
      cropSuccess(imgDataUrl) {
        this.avatar = imgDataUrl;
      },
      cropUploadSuccess(jsonData) {
        this.newCharacter.passport_photo_id = jsonData.img_id;
      },
      cropUploadFail(status, field) {
        let e = "upload fail - " + status + ", field: " + field;
        console.error(e);
        this.showError({message: e});
      },
      getRank(rank) {
        return API.getRankTitle(rank);
      },
      saveProfile() {
        let self = this;
        if (self.$v.$invalid) {
          return;
        }
        self.isPageLoading = true;
        API.private
          .characterCreate(self.newCharacter)
          .then(() => {
            self.isPageLoading = false;
            self.showSuccess();
            return self.$store.dispatch("loadMember");
          }).then(() => {
            self.$router.push({ name: 'Профиль', force: true })
          })
          .catch(e => {
            if (e) {
              console.error(e);
              this.showError({message: e});
            }
            this.isPageLoading = false;
          });
      },
      capitalize(value) {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      isHasPassport() {
        return this.memberEvent && this.memberEvent.HasPassport;
      },
      isConfirmedRequest() {
        return (
          this.memberEvent &&
          (this.memberEvent.Confirmation === API.consts.Request.Approved ||
            this.memberEvent.Confirmation === API.consts.Request.Paid ||
            this.memberEvent.Confirmation === API.consts.Request.PartiallyPaid)
        );
      },
      catchError(e) {
        let self = this;

        console.error(e);
        if (e.response && e.response.data && e.response.data.error) {
          console.error(e.response.data);
          switch (e.response.data.error) {
            case "ERR_BAD_JWT":
            case "ERR_BAD_AUTH":
            case "ERR_NOT_ALLOWED": {
              API.private.logout();
              self.$router.replace("/login");
            }
              break;
          }
          self.showError({message: e.response});
        }
        self.isPageLoading = false;
      }
    },
    beforeMount: function () {
      this.loadData();
    },
    computed: {
      callsign() {
        return this.newCharacter.callsign;
      }
    },
    watch: {
      callsign(newValue) {
        this.isTouched.callsign = true;
        this.newCharacter.callsign = this.capitalize(newValue);
      },
      selectedSuit(newValue) {
        this.newCharacter.suit_id = newValue.id;
      }
    },
    notifications: {
      showError: {
        title: "Ошибка ",
        type: "error"
      },
      showSuccess: {
        title: "Данные сохранены",
        type: "success"
      }
    }
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
  @media (min-width: 769px) {
    .profile-container {
      max-width: 76%;
    }
  }

  .invalid-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f86c6b;
  }

  .emptySelect .multiselect__content li:last-child {
    display: block !important;
  }

  .multiselect {
    min-height: auto !important;
    padding: 0;
  }

  .multiselect__tags,
  .multiselect__input,
  .multiselect__input:placeholder-shown,
  .multiselect__tags-wrap,
  .multiselect__single,
  .option__img,
  .multiselect__option,
  .multiselect__option--highlight,
  .multiselect__element,
  .multiselect__content {
    background-color: #515b65;
    color: #e4e7ea;
    border: none;
    display: block !important;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background-color: #38404a;
    color: #e4e7ea;
    border: none;
    display: block !important;
  }

  .multiselect--disabled {
    background-color: #38404a;
    color: #e4e7ea;
    border: none;
  }

  .multiselect--disabled .multiselect__select {
    display: none;
  }

  .option__desc {
    margin-left: 10px;
    word-wrap: break-word;
  }

  .option__title,
  .option__desc {
    font: inherit;
    display: block !important;
  }
</style>
